<template>
  <div class="row">
    <div class="col-8">
      <b-card>
        <h4>{{$t('Projects.Code.AddCode')}}</h4>
        <pre class="blockquote font-size-12 mt-5" style="color: #e83e8c;">
(function(w,d,s,l,i){w[l]=w[l]||{};w[l].key=i;
var f=d.getElementsByTagName(s)[0],j=d.createElement(s);
j.async=true;j.src='https://cdn.eefim.com';f.parentNode.insertBefore(j,f);
})(window,document,'script','eefimDataLayer','{{auth_key ? auth_key : "you key"}}');
              </pre>

        <h5>{{$t('Projects.Code.HowInstallCode')}}</h5>
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
          <b-tab :title="$t('Projects.Code.HowInstallCodeHtmlTitle')" active>
            <b-card-text>
              {{$t('Projects.Code.HowInstallCodeHtml')}} <code class="highlighter-rouge"> &lt;head&gt;&lt;/head&gt;</code>
            </b-card-text>
          </b-tab>
          <b-tab :title="$t('Projects.Code.HowInstallCodeCMSTitle')">
            {{$t('Projects.Code.HowInstallCodeCMS')}}
          </b-tab>
          <b-tab :title="$t('Projects.Code.HowInstallCodeGTMTitle')" v-html="$t('Projects.Code.HowInstallCodeGTM')">
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <div class="col-4">
<!--      <b-card bg-variant="primary" class="text-white-50">-->
<!--        <h5 class="mt-0 mb-4 text-white">-->
<!--          <i class="mdi mdi-bullseye-arrow mr-3"></i>-->
<!--          {{$t('Projects.Code.Info.SendCode.Title')}}-->
<!--        </h5>-->
<!--        <p class="card-text">{{$t('Projects.Code.Info.SendCode.Text')}}</p>-->
<!--      </b-card>-->

      <!--            @TODO: добавить форму для отправки кода на email-->
      <!--            <b-card bg-variant="success" class="text-white-50">-->
      <!--              <h5 class="mt-0 mb-4 text-white">-->
      <!--                <i class="mdi mdi-bullseye-arrow mr-3"></i>Отправить код программисту-->
      <!--              </h5>-->
      <!--              <p class="card-text">Введите email вашего программиста и мы отправим ему письмо с кодом и инструкциейй по установке</p>-->
      <!--            </b-card>-->

      <!--            @TODO: добавить форму обратной связи-->
      <b-card header-class="bg-transparent border-info" class="border border-info"  onclick="Chatra('openChat', true)">
        <template v-slot:header>
          <h5 class="my-0 text-info">
            <i class="mdi mdi-alert-circle-outline mr-3"></i>
            {{$t('Projects.Code.Info.Problems.Title')}}
          </h5>
        </template>
        <p class="card-text">
          {{$t('Projects.Code.Info.Problems.Text')}}
        </p>
      </b-card>

    </div>
  </div>
</template>

<script>
export default {
  name: "CodeProject",
  props: ["auth_key"],
  data: function () {
    return {
      // auth_key: ''
    }
  }
}
</script>

<style scoped>

</style>