<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <LoaderBig v-if="loading === true" />

    <div v-if="loading === false" class="row">
      <div class="col-lg-12">

        <div v-if="!Object.keys(projects).length" class="card" id="no-project">
          <div class="card-body">
            <div class="text-center mt-5">
              <h4>{{ $t("Projects.Info.NoProjects.Title") }}</h4>
              <small>{{ $t("Projects.Info.NoProjects.SmallDescription") }}</small>
              <div class="mt-4">
                <router-link class="btn btn-success btn-lg" to="/projects/0">
                  {{ $t("Projects.AddProject.AddProject") }}
                  <i class="ri-arrow-right-line align-middle ml-2"></i>
                </router-link>
              </div>
            </div>
            <div class="row mt-5 justify-content-between">
              <div class="col-6">
                <blockquote class="blockquote font-size-16 mb-0" v-html="$t('Projects.Info.Info')"></blockquote>
              </div>
              <div class="col-5 pt-2">
<!--                <b-card header-class="bg-transparent border-info" class="border border-info">-->
<!--                  <h5 class="mt-0 mb-4 text-info">-->
<!--                    <i class="ri-settings-3-line mr-3"></i> В разработке-->
<!--                  </h5>-->
<!--                  <p class="card-text">-->
<!--                    Скоро мы добавим возможность предостовлять коллегам доступ к конкретным проектам-->
<!--                  </p>-->
<!--                </b-card>-->
              </div>
            </div>
          </div>
        </div>

          <div v-if="Object.keys(projects).length" >
            <div class="mb-4">
              <router-link class="btn btn-success" to="/projects/0">
                <i class="mdi mdi-plus mr-2"></i> {{ $t("Projects.AddProject.AddProject") }}
              </router-link>
            </div>

            <div v-for="(item, index) in projects" :key="index" class="row">
              <div class="col-12">
                <b-card>
                    <b-row align-h="between">
                      <b-col>
                        <h5>
                            {{item.name}}
                        </h5>
                        <small><a :href="item.url">{{item.url}}</a></small>
                      </b-col>
                      <b-col>
                        <!--                      <apexchart-->
                        <!--                          class="apex-charts"-->
                        <!--                          height="35"-->
                        <!--                          dir="ltr"-->
                        <!--                          :series="source3.series"-->
                        <!--                          :options="source3.chartOptions"-->
                        <!--                          type="line"-->
                        <!--                          width="100%"-->
                        <!--                      ></apexchart>-->
                      </b-col>
                      <b-col class="text-right">
                        <router-link class="btn btn-primary btn-sm mr-2" :to="'/projects/' + item.id">
                          <i class="mdi mdi-pencil"></i>
                          {{$t('General.Base.Edit')}}
                        </router-link>
                        <b-button variant="primary" size="sm" @click="showModalProjectCode(item.id)">
                          <i class="fas fa-code"></i>
                          {{$t('Projects.Project.Code')}}
                        </b-button>
                      </b-col>
                    </b-row>
                </b-card>
              </div>
            </div>
          </div>
      </div>
    </div>

    <b-modal
        id="modalProjectCode"
        size="xl"
        :title="$t('Projects.Code.ModalTitle')"
        title-class="font-18"
        hide-footer

    >
      <CodeProject :auth_key = "auth_key" />
    </b-modal>

  </Layout>
</template>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import { projectsService } from '@/helpers/fakebackend/projects.service';
  import LoaderBig from "@/components/LoaderBig";
  import CodeProject from './_code'

  export default {
    components: { Layout, PageHeader, LoaderBig, CodeProject },
    page: {
      title: 'Projects',
      meta: [{ name: "Projects" }],
    },
    data: function () {
      return {
        title: this.$t('General.Breadcrumbs.Projects'),
        items: [
          {
            text: this.$t('General.Breadcrumbs.Main'),
            href: "/",
          },
          {
            text: this.$t('General.Breadcrumbs.Projects'),
            active: true,
          },
        ],
        loading: true,
        projects:[],
        auth_key: null,
        // source3: {
        //   series: [
        //     {
        //       data: [42, 31, 42, 34, 46, 38, 44, 36, 42, 32, 54]
        //     }
        //   ],
        //   chartOptions: {
        //     chart: {
        //       sparkline: {
        //         enabled: true
        //       }
        //     },
        //     stroke: {
        //       width: [3],
        //       curve: "smooth"
        //     },
        //     colors: ["#5664d2"],
        //     tooltip: {
        //       fixed: {
        //         enabled: false
        //       },
        //       x: {
        //         show: false
        //       },
        //       y: {
        //         title: {
        //           formatter: function() {
        //             return "";
        //           }
        //         }
        //       },
        //       marker: {
        //         show: false
        //       }
        //     }
        //   }
        // }
      }
    },
    methods: {
      ...projectsService,
      showModalProjectCode(project_id) {
        this.$bvModal.show('modalProjectCode')

        this.getProject(project_id).then((data) => {
          this.auth_key = data.auth_key
        })

      }
    },
    mounted() {
      this.getProjects().then(result => {
        this.projects = result
        this.loading = false
      })
    }
  }
</script>
